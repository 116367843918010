import Header from '../containers/header';
import React, { Component } from 'react'
import { Route, Switch } from "react-router-dom";

import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import Login from "./login";
import Cours from '../containers/cours'
import Produit from '../containers/produit'
import Of from '../containers/of'
import OfEdit from '../containers/ofEdit'
import requireAuth from '../helpers/require-authentification';
import tracerOf from '../containers/tracerOf';
import Livraison from '../containers/livraison';
import FilterTester from '../containers/FilterTester';
import ofTools from './ofTools';
import Atelier from '../containers/Atelier';
import AnalyseJoaillier from '../containers/atelier/AnalyseJoaillier';
import ReceptionPierre from '../containers/pierre/ReceptionPierre';
import SortiePierre from '../containers/pierre/SortiePierre';
import StockPierre from './StockPierre';
import StockProduit from './StockProduit';
import CentreVide from './produit/CentreVide';
import Centre from './produit/Centre';
import SuppressionCvPierre from '../helpers/SuppressionCvPierre';
import ListePierre from '../containers/pierre/ListePierre';
import CreateTraite from './CreateTraite';
import Avoir from '../containers/Avoir';
import ImageLoader from './ImageLoader';
import Images from './Images';
import RetourPierre from './pierre/RetourPierre';
import Client from '../containers/forms/Client';
import AffectationImage from './image/AffectationImage';
import AnalyseCa from '../containers/analyse/AnalyseCa';
import SuiviConnection from '../containers/analyse/SuiviConnection';
import ComparaisonCa from '../containers/analyse/ComparaisonCa';
import AnalyseFournisseur from '../containers/analyse/AnalyseFournisseur';
import CreateTraiteFournisseur from './CreateTraiteFournisseur';
import AnalyseStock from '../containers/analyse/AnalyseStock';
import Compta from '../containers/compta/Compta';
import PlanningHebdomadaire from './planning/Planning';
import Besoin from '../containers/besoin/Besoin';
import ValorisationStock from '../containers/compta/ValorisationStock';
import AnalyseFacon from '../containers/analyse/AnalyseFacon';
import AnalyseAtelier from '../containers/atelier/analyse/AnalyseAtelier';
import MouvementJoaillier from '../containers/atelier/MouvementJoaillier';
import MouvementSertisseur from '../containers/atelier/MouvementSertisseur';
import FinaliserOf from '../containers/atelier/finaliser/FinaliserOf';
import EnvoiBoiteOr from '../containers/atelier/envoi/EnvoiBoiteOr';
import ReservationCollection from './produit/reservation/ReservationCollection';
import SuiviMetal from '../containers/analyse/SuiviMetal';
import SvgGenerator from './svg/SvgGenerator';
import UpdateWordpress from './wordpress/UpdateWordpress';
require("../style.css");
class App extends Component {
  render() {
    return (
      <div>
        {this.props.isLoggedIn && < Header />}
        <div className="container-fluid body_content">
          <Switch>
            <Route exact path="/cours" component={requireAuth(Cours)} />
            <Route exact path="/svg" component={requireAuth(SvgGenerator)} />
            <Route exact path="/wp" component={requireAuth(UpdateWordpress)} />
            <Route exact path="/produit" component={requireAuth(Produit)} />
            <Route exact path="/of" component={requireAuth(Of)} />
            <Route exact path="/planning" component={requireAuth(PlanningHebdomadaire)} />
            <Route exact path="/of/edit" component={requireAuth(OfEdit)} />
            <Route exact path="/of/tracer" component={requireAuth(tracerOf)} />
            <Route exact path="/of/tools" component={requireAuth(ofTools)} />
            <Route exact path="/of/besoin" component={requireAuth(Besoin)} />
            <Route exact path="/livraion" component={requireAuth(Livraison)} />
            <Route exact path="/filter" component={requireAuth(FilterTester)} />
            <Route exact path="/analyse_ca" component={requireAuth(AnalyseCa)} />
            <Route exact path="/suivi_metal" component={requireAuth(SuiviMetal)} />

            <Route exact path="/suivi_connection" component={requireAuth(SuiviConnection)} />
            <Route exact path="/analyse_fournisseur" component={requireAuth(AnalyseFournisseur)} />

            <Route exact path="/comparaison_ca" component={requireAuth(ComparaisonCa)} />
            <Route exact path="/analyse_stock" component={requireAuth(AnalyseStock)} />
            <Route exact path="/facon" component={requireAuth(AnalyseFacon)} />


            <Route exact path="/atelier" component={requireAuth(Atelier)} />
            <Route exact path="/atelier/analyse" component={requireAuth(AnalyseJoaillier)} />
            <Route exact path="/atelier/analyse_ouvrier" component={requireAuth(AnalyseAtelier)} />
            <Route exact path="/atelier/envoi" component={requireAuth(MouvementJoaillier)} />
            <Route exact path="/serti/envoi" component={requireAuth(MouvementSertisseur)} />
            <Route exact path="/finaliser" component={requireAuth(FinaliserOf)} />
            <Route exact path="/atelier/boite_or" component={requireAuth(EnvoiBoiteOr)} />




            <Route exact path="/pierre/reception" component={requireAuth(ReceptionPierre)} />
            <Route exact path="/pierre/sortie" component={requireAuth(SortiePierre)} />
            <Route exact path="/pierre/stock" component={requireAuth(StockPierre)} />
            <Route exact path="/pierre/retour" component={requireAuth(RetourPierre)} />
            <Route exact path="/produit/stock" component={requireAuth(StockProduit)} />
            <Route exact path="/produit/cv" component={requireAuth(CentreVide)} />
            <Route exact path="/produit/centre" component={requireAuth(Centre)} />
            <Route exact path="/reservation" component={requireAuth(ReservationCollection)} />

            <Route exact path="/test" component={requireAuth(SuppressionCvPierre)} />
            <Route exact path="/pierre" component={requireAuth(ListePierre)} />
            <Route exact path="/compta" component={requireAuth(Compta)} />
            <Route exact path="/valorisation" component={requireAuth(ValorisationStock)} />
            <Route exact path="/traite" component={requireAuth(CreateTraite)} />
            <Route exact path="/traite_fournisseur" component={requireAuth(CreateTraiteFournisseur)} />
            <Route exact path="/avoir" component={requireAuth(Avoir)} />
            <Route exact path="/upload" component={requireAuth(ImageLoader)} />
            <Route exact path="/image" component={requireAuth(Images)} />
            <Route exact path="/client" component={requireAuth(Client)} />
            <Route exact path="/affectation" component={requireAuth(AffectationImage)} />
            <Route path="/" component={Login} />
          </Switch>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  isLoggedIn: state.authentification.isLoggedIn
});
export default withRouter(connect(mapStateToProps)(App))
