import React, { useState } from 'react';
import { Button, LinearProgress, Typography, Box } from '@material-ui/core';
import axios from 'axios';

const UpdateWordpress = () => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('');

    const handleUpdate = async () => {
        try {
            setStatus('Vérification des images...');
            setProgress(10);

            // Étape 1 : Récupérer les produits via l'API
            const { data } = await axios.get('https://extensia-france.com.com/api/wordpress/get_product.php');
            const products = data; // Liste des produits

            setProgress(30);

            // Étape 2 : Vérifier et uploader les images si besoin
            for (let product of products) {
                for (let variation of product.produits) {
                    const imageExists = await axios.post('https://boutique.extensia-joaillerie.fr/api/check_image', {
                        image: variation.image,
                    });

                    if (!imageExists.data.exists) {
                        // Uploader l'image si elle n'existe pas
                        await axios.post('https://boutique.extensia-joaillerie.fr/api/upload_image', {
                            image: variation.image,
                        });
                    }
                }
            }

            setStatus('Mise à jour des produits WordPress...');
            setProgress(60);

            // Étape 3 : Créer ou mettre à jour les produits sur WordPress
            for (let product of products) {
                await axios.post('https://boutique.extensia-joaillerie.fr/api/update_product', {
                    product,
                });
            }

            setProgress(100);
            setStatus('Mise à jour terminée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la mise à jour :', error);
            setStatus('Une erreur est survenue.');
        }
    };

    return (
        <Box sx={{ width: '100%', textAlign: 'center' }}>
            <Typography variant="h6" gutterBottom>
                Mise à jour des produits sur WordPress
            </Typography>
            <Button variant="contained" onClick={handleUpdate}>
                Mise à jour WordPress
            </Button>
            {progress > 0 && (
                <Box sx={{ width: '100%', mt: 2 }}>
                    <LinearProgress variant="determinate" value={progress} />
                    <Typography variant="body1">{status}</Typography>
                </Box>
            )}
        </Box>
    );
};

export default UpdateWordpress;
